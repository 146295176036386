

body {
  margin: 0;
  font-family: "Raleway", Arial, sans-serif;
  color: #868788;
}

.text-padding {
  padding-left: 290px;
}

.admin-container.container-fluid {
  padding-left: 16.5rem;
}

.table-container.container-fluid {
  padding-left: 16.5rem;
}

.colorPicker {
  margin-top: 5px;
  width: 100px;
}

.form-label {
  font-weight: bold;
}

.large-bold {
  font-size: 120%;
}

.deleteIconGroup {
  padding-top: 2.25rem;
}

.editPasswordButton {
  padding-top: 2rem;
}

.addEditProductColorPillWrapper {
  padding-top: 2.25rem;
}

.addEditProductColorPill {
  color: white;
  display: inline-block;
}

.productPill {
  color: white;
  display: inline-block;
}

.tankGroup {
  margin-left: 5rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.menu {
  height:150px;
  width: 100%;
  margin-bottom: 50px;
}

.width-100 {
  max-width: 100%;
}
.main_page1 {
  height: 1600px;
  max-width: 100%;
}

.main_page2 {
  height: 1200px;
  max-width: 100%;
}


.image {
  height: 700px;
  width: 100%;
}

.image2 {
height: 1800px;
width: 100%;
}

.map_image {
  height: 300px;
  width: 100%;
}

.explore_logo {
  margin-bottom: 170px;
}

.logo_image {
  width: 130px;
  height: 100px;
  margin: 40px;
  margin-left: 35%;
}

.home_cards {
  color: #868788;
}

.carousel2 {
  font-size: x-large;

}

.fontstyle {
  font-family: "Arial", sans-serif;
  color: #868788;
}

.wide_dash {
  margin: 70px;
  border-top: .5px #868788 solid;
  margin-left: 20px;
  margin-right: 20px;
}

.wide_dash_grey {
  margin: 70px;
  border-top: .5px #a6b0ad solid;
  margin-left: 20px;
  margin-right: 20px;
}

.narrow_dash {
  border-top: .5px #868788 solid;
  margin-left: 1px;
  margin-right: 1px;
}

.main_page1_subsection {
  margin-left: 15%;
  margin-right: 15%;
  font-weight: bolder;

}


.grid-parent {
  display: flex;
  flex-direction: column;
  /*grid-template-columns: auto auto auto auto auto auto;*/
  position: relative;
  gap: 20px;
  padding: 20px;
  margin: 100px;
}


.icon-container {
  position: absolute;
  left: 90px;
  top: 175px;
  /*display: flex;*/
  /*gap: 30px;*/
}
.icon-container-notes {
  position: absolute;
  left: 150px;
  top: 178px;
  display: flex;
  gap: 30px;
}

.back-icon {
  position: absolute;
  left: 20px;
  top: 175px;
}

.folder-title {
  position: absolute;
  left: 250px;
  top: 175px;
}
.preview-child {
  /*border: 1px solid #c5c5c5;*/
  /*justify-content: center;*/
  display: flex;
  align-items: center;
  /*height: 100px;*/
  flex-direction: row;
  cursor: pointer;
  border-radius: 10px;
  /*gap: 10px;*/
}

.grid-child h4 {
  color: #777777;
}

.image-preview {
  width: 100px;
}

.progress-bar {
  padding: 20px;
}

.progress-bar-container {
  position: relative;
  width: 100%;
  top: 100px;
}


.folder-badge {
  position: relative;
  top: -2px;
}

.document-delete {
  position: relative;
  /*right: 10px;*/
}

.pdf-view {
  border: 1px solid #c5c5c5;
  width: 900px;
  height: 1000px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0;
}

.upload-btn-wrapper input[type=file] {
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.file-modal-button-container {
  position: absolute;
  right: 30px;
  top: 40px;
}


/* Alert Box */

.alertbox {
  flex-direction: row;
  display: flex;
  height:50px;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
}

.file-alert {
  width: 80%;
}

/* Admin page */

.admin-menu {
  width: 200px;
  margin: 10px;
}

.admin-selectclient {
  margin-top:10px;
  width: 300px;
}

#detail {
  flex: 1;
  padding: 2rem 4rem;
  margin-left: 150px;
  width: 100%;
}

.active {
  color: white !important;
  background-color: #2192bb; /* Sets the background to a blue color */
  border-radius: .25rem; /* Adds a bit of rounding to the corners */
}

.explore_logo_center_screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}